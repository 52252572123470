<template>
  <div v-if="form">
  <page-title-component title="Dashboard"></page-title-component>
  <div class="row">
    <div class="col-md-6 col-lg-3">
      <div class="card report-card">
        <div class="card-body">
          <div class="row d-flex justify-content-center">
            <div class="col">
              <p class="text-dark mb-0 fw-semibold">Total Clients</p>
              <h3 class="m-0">{{ totalResponse }}</h3>
            </div>
            <div class="col-auto align-self-center">
              <div class="report-main-icon bg-light-alt">
                <i class="fas fa-comment-dots"></i>
              </div>
            </div>
          </div>
        </div><!--end card-body-->
      </div><!--end card-->
    </div>
  </div>

  </div>
</template>
<script>
import PageTitleComponent from "@/components/PageTitleComponent";
import { mapGetters } from 'vuex';
export default {
  components: {PageTitleComponent},
  data() {
    return {
      totalResponse: '-'
    }
  },
  computed: {
    ...mapGetters({
      form: "form/getForm"
    })
  },
  created() {
    this.getTotalResponse()

    // this.axios.get('/test');
  },
  methods: {
    getTotalResponse() {
      this.axios.get('/reports/total-response')
        .then(res => {
          this.totalResponse = res.data.data.total_response
        })
    }
  }
}
</script>