<template>
  <page-title-component title="Logs" />

  <div class="row">
    <div class="col-md-12">
      <table-component
          :columns="columns"
          :rows="logs"
          :sortable="sortable"
          @sorting="sorting"
          :pagination="pagination"
          @search="search"
          @pageChanged="pageChanged">

        <template v-slot:status_code="{ row }">
          <span class="badge bg-success" v-if="row.status_code.charAt(0) == 2">{{ row.status_code }}</span>
          <span class="badge bg-warning" v-else-if="row.status_code.charAt(0) == 4">{{ row.status_code }}</span>
          <span class="badge bg-danger" v-else-if="row.status_code.charAt(0) == 5">{{ row.status_code }}</span>
          <span class="badge bg-blue" v-else>{{ row.status_code }}</span>
        </template>

        <template v-slot:action="{ row }">
          <button class="btn btn-sm btn-info me-1" @click="$refs.detailsModal.show(row)">
            <i class="fas fa-eye"></i>
          </button>
        </template>
      </table-component>
    </div>
  </div>

  <details-modal ref="detailsModal" />
</template>

<script>
import TableComponent from "@/components/TableComponent";
import PageTitleComponent from "@/components/PageTitleComponent";
import DetailsModal from "@/views/logs/DetailsModal.vue";
export default {
  components: {TableComponent, PageTitleComponent, DetailsModal},
  data() {
    return {
      logs: [],
      queryParams: {
        token: this.$route.query.token,
        page: 1,
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
      columns: [
        {
          label: "Created At",
          field: 'created_at',
          sortable: true,
          searchable: false,
        },
        {
          label: "Name",
          field: 'user.name',
          sortable: true,
          searchable: true,
        },
        {
          label: "Email",
          field: 'user.email',
          sortable: true,
          searchable: true,
        },
        {
          label: "Controller",
          field: 'controller_name',
          sortable: false,
          searchable: false,
        },
        {
          label: "Function",
          field: 'function_name',
          sortable: false,
          searchable: false,
        },
        {
          label: "Method",
          field: 'method',
          sortable: true,
          searchable: false,
        },
        {
          label: "Status Code",
          field: 'status_code',
          sortable: true,
          searchable: false,
          slot: true
        },
        {
          label: "Action",
          field: 'action',
          sortable: false,
          slot: true,
          class: ['text-end']
        }
      ],
      pagination: null,
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.logs = [];

      this.axios.get('/logs', {params: this.queryParams})
          .then((response) => {
            this.logs = response.data.data;
            this.pagination = response.data.meta;
          })
    }
  }
}
</script>