<template>
  <div class="modal fade"
       id="modal-log-details"
       tabindex="-1"
       role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title m-0 me-3" id="exampleModalDefaultLabel">Details</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div><!--end modal-header-->
        <div class="modal-body">
          <table class="table table-bordered" v-if="log">
            <tbody>
            <tr>
              <th>Created At</th>
              <td>{{ log.created_at }}</td>
            </tr>
            <tr>
              <th>User</th>
              <td>{{ log.user ? (log.user.name + ' (' + log.user.email + ')') : '' }}</td>
            </tr>
            <tr>
              <th>URL</th>
              <td>{{ log.url }}</td>
            </tr>
            <tr>
              <th>Controller</th>
              <td>{{ log.controller_name }}</td>
            </tr>
            <tr>
              <th>Function</th>
              <td>{{ log.function_name }}</td>
            </tr>
            <tr>
              <th>Method</th>
              <td>{{ log.method }}</td>
            </tr>
            <tr>
              <th>Status Code</th>
              <td>{{ log.status_code }}</td>
            </tr>
            <tr>
              <th>Headers</th>
              <td>
                <vue-json-pretty :data="log.headers" /></td>
            </tr>
            <tr>
              <th>Payload</th>
              <td>
                <vue-json-pretty :data="log.payload" /></td>
            </tr>
            <tr>
              <th>Body</th>
              <td>
                <vue-json-pretty :data="log.body" /></td>
            </tr>
            <tr>
              <th>Response</th>
              <td>
                <vue-json-pretty :data="log.response" /></td>
            </tr>
            </tbody>
          </table>
        </div><!--end modal-body-->
        <div class="modal-footer">
          <button type="button" class="btn btn-soft-secondary btn-sm" data-bs-dismiss="modal">Close</button>
        </div>
      </div><!--end modal-content-->
    </div><!--end modal-dialog-->
  </div><!--end modal-->
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      modal: null,
      log: null
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-log-details'))
  },
  methods: {
    show(log) {
      this.log = log;
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    }
  },

}
</script>